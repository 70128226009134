<template>
  <div>
    <Myhead :nav="2"></Myhead>
    <FirstHeader :title="Language.serviceProducts.service_products" img-url="serviceProducts"/>
    <div class="item">
      <a href="#product1" :class="nav == -1 ? 'item-checked':''" @click="changenav(-1)">{{Language.serviceProducts.Christmas_lighting_and_gifts}}</a>
      <a href="#product2" :class="nav == 1 ? 'item-checked':''" @click="changenav(1)">{{Language.serviceProducts.Infant_and_child_products_andtoys}}</a>
      <a href="#product3" :class="nav == 2 ? 'item-checked':''" @click="changenav(2)">{{Language.serviceProducts.textile}}</a>
      <a href="#product4" :class="nav == 3 ? 'item-checked':''" @click="changenav(3)">{{Language.serviceProducts.Outdoor_products}}</a>
      <a href="#product5" :class="nav == 4 ? 'item-checked':''" @click="changenav(4)">{{Language.serviceProducts.Furniture_teaching_aids}}</a>
      <a href="#product6" :class="nav == 5 ? 'item-checked':''" @click="changenav(5)">{{Language.serviceProducts.electrical_and_electronic_product}}</a>
      <a href="#product7" :class="nav == 6 ? 'item-checked':''" @click="changenav(6)">{{Language.serviceProducts.Food_contact_materials}}</a>
    </div>
    <div>
      <!--圣诞灯饰及礼品-->
      <div class="product-cate" id="product1">
        <div class="cate-container">
          <div class="text-box">
            <div class="cate-title">
              <img style="width: 40px; height: 40px;" src="../assets/img/serviceProducts/gift.png" :alt="Language.serviceProducts.Christmas_lighting_and_gifts"/>
              <div style="margin-left: 16px;">{{Language.serviceProducts.Christmas_lighting_and_gifts}}</div>
            </div>
            <div class="cate-text">
              <div>{{Language.serviceProducts.Lighting_and_accessories}}</div>
              <div>{{Language.serviceProducts.Safety_testing}}</div>
              <div>{{Language.serviceProducts.chemical_test}}</div>
              <div>{{Language.serviceProducts.performance_testing}}</div>
              <div>{{Language.serviceProducts.Reliability_testing}}</div>
            </div>
            <div class="btn-learnMore" @click="tochildProduct(1)">{{Language.serviceProducts.LearnMore}}</div>
          </div>
          <div class="img-box">
            <img class="imgStyle" src="../assets/img/serviceProducts/product1.png" :alt="Language.serviceProducts.Christmas_lighting_and_gifts"/>
            <div class="border-box"></div>
          </div>
        </div>
      </div>
      <!--婴童用品及玩具-->
      <div class="product-cate" id="product2">
        <div class="cate-container">
          <div class="text-box">
            <div class="cate-title">
              <img style="width: 40px; height: 40px;" src="../assets/img/serviceProducts/feeder.png" :alt="Language.serviceProducts.Infant_and_child_products_andtoys"/>
              <div style="margin-left: 16px;">{{Language.serviceProducts.Infant_and_child_products_andtoys}}</div>
            </div>
            <div class="cate-text">
              <div>{{Language.serviceProducts.Infant_and_child_products_andtoys1}}</div>
              <div>{{Language.serviceProducts.message1}}</div>
              <div>{{Language.serviceProducts.message2}}</div>
              <div>{{Language.serviceProducts.message3}}</div>
              <div>{{Language.serviceProducts.message4}}</div>
              <div>{{Language.serviceProducts.message5}}</div>
            </div>
            <div class="btn-learnMore" @click="tochildProduct(2)">{{Language.serviceProducts.LearnMore}}</div>
          </div>
          <div class="img-box">
            <img class="imgStyle" src="../assets/img/serviceProducts/product2.png" :alt="Language.serviceProducts.Infant_and_child_products_andtoys"/>
            <div class="border-box"></div>
          </div>
        </div>
      </div>
      <!--纺织品-->
      <div class="product-cate" id="product3">
        <div class="cate-container">
          <div class="text-box">
            <div class="cate-title">
              <img style="width: 40px; height: 40px;" src="../assets/img/serviceProducts/cloth.png" :alt="Language.serviceProducts.textile"/>
              <div style="margin-left: 16px;">{{Language.serviceProducts.textile}}</div>
            </div>
            <div class="cate-text">
              <div>{{Language.serviceProducts.message6}}</div>
              <div class="cate-text-flex" >
                <div style="width: 50%;">
                  <ul style="padding-inline-start: 30px;margin-block-end: 0">
                    <li>{{Language.serviceProducts.message7}}</li>
                    <li>{{Language.serviceProducts.message8}}</li>
                    <li>{{Language.serviceProducts.message9}}</li>
                    <li>{{Language.serviceProducts.message10}}</li>
                    <li>{{Language.serviceProducts.message11}}</li>
                    <li>{{Language.serviceProducts.message12}}</li>
                    <li>{{Language.serviceProducts.message13}}</li>
                    <li>{{Language.serviceProducts.message14}}</li>
                  </ul>
                </div>
                <div style="width: calc(50% - 30px);">
                  <ul style="padding-inline-start: 30px;margin-block-end: 0">
                    <li>{{Language.serviceProducts.message15}}</li>
                    <li>{{Language.serviceProducts.message16}}</li>
                    <li>{{Language.serviceProducts.message17}}</li>
                    <li>{{Language.serviceProducts.message18}}</li>
                    <li>{{Language.serviceProducts.message19}}</li>
                    <li>{{Language.serviceProducts.message20}}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="btn-learnMore" @click="tochildProduct(3)">{{Language.serviceProducts.LearnMore}}</div>
          </div>
          <div class="img-box">
            <img class="imgStyle" src="../assets/img/serviceProducts/product3.png" alt=""/>
            <div class="border-box"></div>
          </div>
        </div>
      </div>
      <!--户外用品-->
      <div class="product-cate" id="product4">
        <div class="cate-container">
          <div class="text-box">
            <div class="cate-title">
              <img style="width: 40px; height: 40px;" src="../assets/img/serviceProducts/tent.png" alt/>
              <div style="margin-left: 16px;">{{Language.serviceProducts.Outdoor_products}}</div>
            </div>
            <div class="cate-text">
              <div>{{Language.serviceProducts.message21}}</div>
              <div>{{Language.serviceProducts.message22}}</div>
              <div>{{Language.serviceProducts.message23}}</div>
              <div>{{Language.serviceProducts.message24}}</div>
            </div>
            <div class="btn-learnMore" @click="tochildProduct(4)">{{Language.serviceProducts.LearnMore}}</div>
          </div>
          <div class="img-box">
            <img class="imgStyle" src="../assets/img/serviceProducts/product4.png" alt/>
            <div class="border-box"></div>
          </div>
        </div>
      </div>
      <!--家具教具-->
      <div class="product-cate" id="product5">
        <div class="cate-container">
          <div class="text-box">
            <div class="cate-title">
              <img style="width: 40px; height: 40px;" src="../assets/img/serviceProducts/sofa.png" alt/>
              <div style="margin-left: 16px;">{{Language.serviceProducts.Furniture_teaching_aids}}</div>
            </div>
            <div class="cate-text">
              <div>{{Language.serviceProducts.message25}}</div>
              <div style="margin-bottom: 8px;">{{Language.serviceProducts.message26}}</div>
              <div>{{Language.serviceProducts.message27}}</div>
              <div style="margin-bottom: 8px;">{{Language.serviceProducts.message28}}</div>
              <div>{{Language.serviceProducts.message29}}</div>
              <div>{{Language.serviceProducts.message30}}</div>
            </div>
            <div class="btn-learnMore" @click="tochildProduct(5)">{{Language.serviceProducts.LearnMore}}</div>
          </div>
          <div class="img-box">
            <img class="imgStyle" src="../assets/img/serviceProducts/product5.png" alt="Furniture teaching aids"/>
            <div class="border-box"></div>
          </div>
        </div>
      </div>
      <!--电子电器产品-->
      <div class="product-cate" id="product6">
        <div class="cate-container">
          <div class="text-box">
            <div class="cate-title">
              <img style="width: 40px; height: 40px;" src="../assets/img/serviceProducts/watch.png" alt/>
              <div style="margin-left: 16px;">{{Language.serviceProducts.electrical_and_electronic_product}}</div>
            </div>
            <div class="cate-text">
              <div>{{Language.serviceProducts.message31}}</div>
              <div>{{Language.serviceProducts.message32}}</div>
              <div>{{Language.serviceProducts.message33}}</div>
            </div>
            <div class="btn-learnMore" @click="tochildProduct(6)">{{Language.serviceProducts.LearnMore}}</div>
          </div>
          <div class="img-box">
            <img class="imgStyle" src="../assets/img/serviceProducts/product6.png" alt="Furniture teaching aids"/>
            <div class="border-box"></div>
          </div>
        </div>
      </div>
      <!--食品接触材料-->
      <div class="product-cate" id="product7">
        <div class="cate-container">
          <div class="text-box">
            <div class="cate-title">
              <img style="width: 40px; height: 40px;" src="../assets/img/serviceProducts/tableware.png" alt/>
              <div style="margin-left: 16px;">{{Language.serviceProducts.Food_contact_materials}}</div>
            </div>
            <div class="cate-text">
              <div>{{Language.serviceProducts.message34}}</div>
              <div>{{Language.serviceProducts.message35}}</div>
              <div>{{Language.serviceProducts.message36}}</div>
              <div>{{Language.serviceProducts.message37}}</div>
              <div>{{Language.serviceProducts.message38}}</div>
            </div>
            <div class="btn-learnMore" @click="tochildProduct(7)">{{Language.serviceProducts.LearnMore}}</div>
          </div>
          <div class="img-box">
            <img class="imgStyle" src="../assets/img/serviceProducts/product7.png" alt="Furniture teaching aids"/>
            <div class="border-box"></div>
          </div>
        </div>
      </div>
    </div>
    <baojia></baojia>
    <Myfoot></Myfoot>
  </div>
</template>
<script>
import FirstHeader from "@/component/firstHeader";
import Myhead from "@/component/myhead";
import Myfoot from "@/component/myfoot";
import baojia from "@/component/baojia";
export default {
  inject:["reload",'L'],
  computed:{
    Language(){
      return this.L()
    }
  },
  components: {
    FirstHeader,
    Myhead,
    Myfoot,
    baojia
  },
  data(){
    return{
      nav:-1
    }
  },
  methods:{
    tochildProduct(type){
      if(type == 1){
        this.$router.push("/products/christmas-lights-and-gifts")
      }
      if(type == 2){
        this.$router.push("/products/baby-products-and-toys")
      }
      if(type == 3){
        this.$router.push("/products/apparel-and-textiles")
      }
      if(type == 4){
        this.$router.push("/products/outdoor-goods")
      }
      if(type == 5){
        this.$router.push("/products/furniture")
      }
      if(type == 6){
        this.$router.push("/products/electronics")
      }
      if(type == 7){
        this.$router.push("/products/food-contact-material")
      }

    },
    changenav(e){
      this.nav = e
    },
  }
}
</script>
<style lang="scss" scoped>
.item {
  height: 80px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #FFFFFF;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    height: 80px;
    //line-height: 80px;
    color: #000000;
    display: flex;
    align-items: center;
    padding: 0px 5px;
  }

  .item-checked {
    color: var(--theme-color1);
    border-bottom: 2px solid var(--theme-color1);
    font-weight: bold;

  }
}

.product-cate {
  background-color: #fafafa;
  padding: 80px 0;

  .cate-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text-box{
      width: calc(100% - 585px);
    }
    .cate-title {
      display: flex;
      font-size: 42px;
      color: var(--title-color);
      margin-bottom: 20px;
      img{
        margin-top: 10px;
      }
    }

    .cate-text {
      color: var(--text-color);
      line-height: 28px;
      .cate-text-flex{
        display: flex;
      }
    }

    .img-box {
      width: 585px;
      height: 330px;
      background-color: #e8e8e8;
      border-radius: 25px;
      position: relative;
      z-index: 1;
      img{
        border-radius: 20px;
      }

      .border-box {
        width: 380px;
        height: 215px;
        background-color: #32b164;
        position: absolute;
        top: 135px;
        left: 225px;
        border-bottom-right-radius: 24px;
        z-index: -1;
      }
    }
  }
}

.product-cate:nth-child(2n) {
  background-color: #ffffff;
}


@media (min-width: 0px) and (max-width: 1200px){
  .item{
    width: 100%;
    padding:0 20px;
    align-items: baseline;
  }
  .product-cate{
     .cate-container{
       padding: 0px 20px;
       .cate-text{
         .cate-text-flex{
           justify-content: flex-start;
         }
       }
     }
  }
  .border-box{
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 450px){
  .item{
    height: 100px;
    a{
      height: 100px;
    }
  }
}
@media (min-width: 0px) and (max-width: 1000px){
  .product-cate{
    .cate-container{
      padding: 0px 20px;
      flex-wrap: wrap;
      .cate-title{
        justify-content: center;
      }
      .text-box{
        width: 100%;
        text-align: center;
      }
      .btn-learnMore{
        margin: 20px auto;
      }

      .img-box{
        margin: auto;
      }
      .cate-text{
        .cate-text-flex{
          justify-content: flex-start;
          text-align: left;
          width: 100%;
        }
      }
    }
  }
}
</style>
