<template>
  <div>
    <div class="form">
      <div class="form-title">{{ Language.baojia.Get_a_quote }}</div>
      <div class="form-tel">{{ Language.baojia.Welcome_to_inquire_by_phone }}：+86-591-8756 2601</div>
      <div class="form-body">
        <div class="form-row">
          <input v-model="formdata.user_name" :placeholder="Language.baojia.user_name"/>
          <input v-model="formdata.email" :placeholder="Language.baojia.email"/>
        </div>
        <div class="form-row">
          <input v-model="formdata.mobile" :placeholder="Language.baojia.mobile"/>
          <input v-model="formdata.company_name" :placeholder="Language.baojia.company_name"/>
        </div>
        <textarea v-model="formdata.problem" :placeholder="Language.baojia.problem"/>
      </div>
      <div class="btn1" @click="formsubmit">{{ Language.baojia.submit }}</div>
    </div>
  </div>
</template>

<script>
export default {
  inject:['reload','L'],
  computed:{
    Language(){
      return this.L()
    }
  },
  name: "mybaojia",
  props: [],
  data() {
    return {
      formdata: {
        user_name: "",
        email: "",
        mobile: "",
        company_name: "",
        problem: "",
      },
    }
  },
  methods: {
    formsubmit() {
      var data = {
        user_name: this.formdata.user_name,
        email: this.formdata.email,
        mobile: this.formdata.mobile,
        problem: this.formdata.problem,
        company_name: this.formdata.company_name,
        type: 2,
      }
      console.log(data)
      if (!data.email && !data.mobile) {
        this.$toast(this.Language.baojia.msg1)
        return
      }
      if (!data.user_name) {
        this.$toast(this.Language.baojia.msg2)
        return
      }
      if (!data.company_name) {
        this.$toast(this.Language.baojia.msg3)
        return
      }
      if (!data.problem) {
        this.$toast(this.Language.baojia.msg4)
        return
      }
      this.$post("/m/indexset/link_record", data).then(res => {
        console.log(res)
        if (res.data.status == 0) {
          this.$toast(res.data.msg)
        } else {
          this.$toast(this.Language.baojia.msg5)
          this.formdata = {
            user_name: "",
            email: "",
            mobile: "",
            company_name: "",
            problem: "",
          }
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.form {
  height: 750px;
  background-image: url("../assets/img/ourServices/form_bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #0d0d0d;

  .form-title {
    font-size: 36px;
    margin-top: 72px;
  }

  .form-tel {
    font-weight: 600;
    margin-top: 24px;
  }

  .form-body {
    margin-top: 48px;
    width: 1200px;

    .form-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 36px;

      input {
        width: 560px;
        height: 36px;
        border: 1px solid #FFFFFF;
        border-radius: 6px;
        padding: 0px 10px;
      }

      input::placeholder {
        padding-left: 10px;
        color: #b1b1b1;
      }

      input:focus {
        outline: 1px solid var(--theme-color1);
      }
    }

    textarea {
      width: 1200px;
      height: 200px;
      border: 1px solid #FFFFFF;
      border-radius: 6px;
      margin-bottom: 36px;
      padding: 10px;
    }

    textarea::placeholder {
      padding-left: 10px;
      padding-top: 10px;
      color: #b1b1b1;
    }

    textarea:focus {
      outline: 1px solid var(--theme-color1);
    }
  }
}

@media (min-width: 800px) and (max-width: 1200px){
  .form{
    .form-body{
      width: 100%;
      padding: 0 20px;
      .form-row{
        input{
          width: 45%;
        }
      }
      textarea{
        width: calc(100%);
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 800px){
  .form{
    .form-body{
      width: 100%;
      padding: 0 20px;
      .form-row{
        input{
          width: 45%;
        }
      }
      textarea{
        width: calc(100%);
      }
    }
  }
}
</style>